import React from 'react';
import './App.css';
import Portfolio from './components/Portfolio/Portfolio';
import NavBar from './components/NavBar/NavBar'
function App() {
  return(
    <div className="App">
      <NavBar></NavBar>
      <Portfolio></Portfolio>
    </div>
  )

}

export default App;
