import React, { useEffect, useState } from 'react';
import './Portfolio.css';
import Zoom from 'react-reveal/Zoom';
import moi from "../../moi.png"
import arrow from "../../arrowRight.png"
import jpma from "../../pf/jpma.jpg"
import Project from '../Project/Project';
import projects from "../../projects.json"
import walletLink from "../../pf/walletLinkTezos.png"
import id360 from "../../pf/id360.png"
import discord from "../../pf/discord.png"
import stinkat from "../../pf/stinkat.png"
import walletProvider from "../../pf/walletProvider1.png"


function Portfolio() {
    const [arrowClass, setArrowClass] = useState("")
    const handleScroll = () => {
        setArrowClass("none")
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <div id='portfolio'>
            <div id='box1'>
                <div id='box2Text'>
                    <div id='box1Text'>
                        <p className='whiteP' id='achilleP'>Achille Dorier</p>
                        <p className='greyP box1P'>Node.js, React, Python and web3</p>
                        <p className='greyP box1P'>Quick and reliable dev</p>
                    </div>
                    <img id='arrow' className={arrowClass} src={arrow}></img>

                </div>
                <div id='box1About'>
                    <img id='moi' src={moi}></img>
                    <p className='greyP' id='aboutP'>
                        {projects.resume}</p>
                    <a id='resume' href='/achilledorier.pdf'>Get my resume there</a>
                </div>
            </div>
            <p id='someOfMy'>My projects</p>
            <div id='projects'>
                <Zoom>
                    <Project orientation={"Left"} image={id360} title={projects.id360.title} link={projects.id360.link} github={projects.id360.github} description={projects.id360.description} technologies={projects.id360.technologies}></Project>
                </Zoom>
                <Zoom>
                    <Project orientation={"Right"} image={walletProvider} title={projects.walletProvider.title} link={projects.walletProvider.link} github={projects.walletProvider.github} description={projects.walletProvider.description} technologies={projects.walletProvider.technologies}></Project>
                </Zoom>
                <Zoom>
                    <Project orientation={"Left"} image={jpma} title={projects.jpma.title} link={projects.jpma.link} github={projects.jpma.github} description={projects.jpma.description} technologies={projects.jpma.technologies} id="jpma"></Project>
                </Zoom>
                <Zoom>
                    <Project orientation={"Right"} image={walletLink} title={projects.walletLink.title} link={projects.walletLink.link} github={projects.walletLink.github} description={projects.walletLink.description} technologies={projects.walletLink.technologies}></Project>
                </Zoom>
                <Zoom>
                    <Project orientation={"Left"} image={stinkat} title={projects.stinkat.title} link={projects.stinkat.link} github={projects.stinkat.github} description={projects.stinkat.description} technologies={projects.stinkat.technologies}></Project>
                </Zoom>
                <Zoom>
                    <Project orientation={"Right"} image={discord} title={projects.discordVerifier.title} link={projects.discordVerifier.link} github={projects.discordVerifier.github} description={projects.discordVerifier.description} technologies={projects.discordVerifier.technologies} id="discord"></Project>
                </Zoom>

            </div>

        </div>
    )

}

export default Portfolio;
